/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

img[alt="St Ethelburga's Centre for Reconciliation and Peace"] {
    max-width: 400px;
    display: block;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px !important;
    width: 24px !important;
    position: fixed !important;
    top: -80px !important;
    left: 170px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 200px;
}

/* General sidebar styles */
.bm-menu {
    position: fixed;
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    width: 200px;
    height: 100%;
    overflow: auto;
    /* Just to visualize the extent */
    bottom: 100px;
    top: -100px;
    flex: 1 1 auto !important;


}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.text-art {
    /* background-color: hsla(0, 0%, 50%, 15); */
    font-family: monospace;
    font-size: 0.8rem;
    /* overflow: scroll; */
    white-space: pre-line;
    word-break: unset;
    word-wrap: unset;
    overflow-wrap: unset;
    -webkit-hyphens: unset;
    -moz-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset;
    margin-bottom: 0;
}


/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 100px) {
    .text-art {
        font-size: 0.5rem;
    }
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    .text-art {
        font-size: 0.8rem;
    }
}

.gForm {
    position: relative;
    padding-bottom: 300%;
    /* Adjust based on your form's height */
    height: 0;
}

.gForm iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 300%;

    overflow-x: hidden;
    overflow-Y: hidden;
}

.App {
    height: 100vh;
    margin-bottom: 0%
}

:root {
    --bodyColor: #fff;
}


body {
    height: 100vh;
    background-image: var(--bodyColor) !important;
}

.html {
    height: 100vh;

}

.root {
    height: 100vh;
    margin-bottom: 0%
}

table,
th,
td {
    text-align: left;
}

table {
    table-layout: fixed;
}

th+th {
    width: 60%;
}

th+th+th {
    width: 15%;
}
